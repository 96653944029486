import * as React from "react";
import { logger } from "bernie-core";
import { inject } from "mobx-react";
import { CentralizedWizardRegionProps } from "./typings";
import { LobQueryRouter } from "components/shared/StorefrontWizard/LobQueryRouter";
import { CustomerNotifications } from "components/shared/CustomerNotifications";
import { CustomerNotificationLocation } from "@shared-ui/customer-notifications";
import { getWizardLOBTemplateComponents } from "src/components/shared/StorefrontWizard/StorefrontWizardUtils";
import { LobFormWithTabs } from "src/components/shared/StorefrontWizard/LobFormWithTabs/LobFormWithTabs";
import { UitkSpacing } from "@egds/react-core/spacing";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren } from "src/utils/RegionUtils";
@logger("CentralizedWizardRegion")
@inject("wizardState", "flexModuleModelStore", "context")
export class CentralizedWizardRegion extends React.Component<CentralizedWizardRegionProps> {
  public render() {
    const { context, wizardState, templateComponent, flexModuleModelStore, blossomComponent } = this.props;

    if (
      !blossomComponent ||
      !hasRenderableChildren(templateComponent, flexModuleModelStore) ||
      !templateComponent.children ||
      templateComponent.children.length < 1
    ) {
      return null;
    }

    const {
      config: { defaultLOB, brandModulePadding },
      children,
    } = templateComponent;

    const wizardChildren = getWizardLOBTemplateComponents(templateComponent.children);

    const isSingleLOB = wizardChildren.length === 1;
    const cometSFPadding = brandModulePadding && brandModulePadding === "comet_padding" ? "comet-homepage-module" : "";

    if (defaultLOB) {
      wizardState.globalWizardState.config.navigation.defaultLob = defaultLOB;
      wizardState.globalWizardState.config.navigation.activeLob = defaultLOB;
    }

    return (
      <UitkSpacing margin={{ blockend: "three" }}>
        <div className={`CentralizedWizardRegion ${cometSFPadding}`} data-testid="centralized-region">
          <div className="SimpleContainer">
            <UitkSpacing padding={{ small: { block: "three" }, medium: { block: "six" }, large: { block: "twelve" } }}>
              <div className="wizard-padding" data-testid="centralized-wizard-region">
                <LobQueryRouter lobsTemplateComponent={children} />
                <UitkSpacing margin={{ blockend: "six" }}>
                  <CustomerNotifications location={CustomerNotificationLocation.AFTER_HEADER} />
                </UitkSpacing>
                {isSingleLOB ? (
                  <UitkSpacing padding={{ small: { inline: "six" }, medium: { inline: "unset" } }}>
                    <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
                  </UitkSpacing>
                ) : (
                  <LobFormWithTabs
                    context={context}
                    wizardFormSpacing={{ padding: { inline: "six" } }}
                    templateComponents={wizardChildren}
                    blossomComponent={blossomComponent}
                  />
                )}
              </div>
            </UitkSpacing>
          </div>
        </div>
      </UitkSpacing>
    );
  }
}

export default CentralizedWizardRegion;
